import ChatMessage from "./ChatMessage";

function ChatMessages({ messages, handleFaqClick }) {
  return (
    <div
      id="austra-chat-messages"
      class="flex flex-col-reverse overflow-y-scroll h-full flex-grow bg-neutralwhite-300 p-[24px]">
      {messages.length ? (
        [...messages]
          .reverse()
          .map((message) => (
            <ChatMessage
              key={message.id}
              {...message}
              handleFaqClick={handleFaqClick}
            />
          ))
      ) : (
        <div />
      )}
    </div>
  );
}

export default ChatMessages;
