import Product from "./Product";
import SnowFlakeIcon from "./SnowFlakeIcon";

function ProductList({ recommendations, handleFaqClick }) {
  return (
    <div class="relative mt-[24px]">
      <div class="absolute -top-3 -left-3 z-10">
        <SnowFlakeIcon size="sm" />
      </div>
      <div class="flex flex-shrink-0 gap-4 overflow-scroll pb-[12px] pt-[1px] px-2 -mx-2 mb-[-12px]">
        {recommendations.map(
          (
            {
              product_id,
              title,
              price,
              image_url,
              rating_count,
              rating_value,
              relevancy,
              featured_review,
              product_url,
              faqs,
            },
            index
          ) => (
            <Product
              key={product_id}
              id={product_id}
              title={title}
              price={price}
              featuredImage={image_url}
              index={index}
              ratingCount={rating_count}
              ratingValue={rating_value}
              relevancy={relevancy}
              review={featured_review}
              productUrl={product_url}
              faqs={faqs}
              handleFaqClick={handleFaqClick}
            />
          )
        )}
      </div>
    </div>
  );
}

export default ProductList;
