// Function to update a cookie's expiration time
export function updateCookieExpiration(cookieName, minutesToExpire) {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();

    // Check if the cookie starts with the specified name
    if (cookie.indexOf(cookieName + "=") === 0) {
      var cookieValue = cookie.substring(cookieName.length + 1);

      // Create a new cookie with the updated expiration time
      var expirationDate = new Date();
      expirationDate.setMinutes(expirationDate.getMinutes() + minutesToExpire);
      var updatedCookie =
        cookieName +
        "=" +
        cookieValue +
        "; expires=" +
        expirationDate.toUTCString() +
        "; path=/";

      // Update the cookie by setting it with the updated value
      document.cookie = updatedCookie;

      break;
    }
  }
}

export function getCookie(cookieName) {
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(";");

  for (var i = 0; i < cookieArray.length; i++) {
    var cookie = cookieArray[i].trim();

    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length);
    }
  }

  return null;
}

export function setCookie(name, value, minutesToExpire) {
  var expirationDate = new Date();
  expirationDate.setMinutes(expirationDate.getMinutes() + minutesToExpire);

  var cookieValue =
    encodeURIComponent(value) +
    "; expires=" +
    expirationDate.toUTCString() +
    "; path=/";
  document.cookie = name + "=" + cookieValue;
}

export function clearCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
