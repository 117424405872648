function ChatHeader({ title, toggleChat }) {
  return (
    <div class="w-full md:h-[7px] bg-austraPurple-900 chat-header">
      <div className="flex items-center justify-between  py-[14px] pr-[18px] pl-[23px] sm:hidden">
        <div className="flex items-center">
          <img
            src="https://www.euclove.com.au/cdn/shop/files/Euclove_logo_450x250_two_tone_c3f93d0d-8a4e-4ec6-aed9-b34b20a6c994.png?v=1676341740&width=180"
            alt="Austra"
            className="w-[46px] rounded-full mr-[12px]"
          />
          <h2 className="text-[24px] text-white font-semibold">{title}</h2>
        </div>

        <button type="button" onClick={toggleChat} className="md:hidden">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="chevron-down">
              <path
                id="Vector"
                d="M6.04492 9.83984L12.0449 15.8398L18.0449 9.83984"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
}

export default ChatHeader;
