import Loader from "./Loader";
import ProductList from "./ProductList";
import SnowFlakeIcon from "./SnowFlakeIcon";
import AnimateText from "./AnimateText";
import Linkify from "linkify-react";
import UserIcon from "./UserIcon";

function CustomerMessage({ message }) {
  return (
    <div class="flex items-center p-[14px] rounded-[12px] max-w-[90%] bg-customerChatMessageBackground-900 rounded-tr-none tracking-normal each-chat-message">
      <p class="whitespace-pre-wrap chatMessage break-anywhere">{message}</p>
    </div>
  );
}

function AgentMessage({ message, loading, animate, senderType }) {
  return (
    <div class="flex w-[90%]">
      <div
        class="flex items-center p-[14px] rounded-[12px] bg-white rounded-tl-none relative tracking-normal"
        style={{
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}>
        <div class="absolute -top-3 -left-3">
          {senderType === "owner" ? (
            <UserIcon size="sm" />
          ) : (
            <SnowFlakeIcon size="sm" />
          )}
        </div>
        {}
        {loading ? (
          <div class="w-full flex justify-center">
            <Loader />
          </div>
        ) : null}
        <div class="w-full">
          {animate ? (
            <AnimateText text={message} />
          ) : (
            <p class="whitespace-pre-wrap chatMessage break-anywhere">
              <Linkify
                options={{
                  target: {
                    url: "_blank",
                  },
                  className: "text-austraPurple-900",
                }}>
                {message}
              </Linkify>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

function ChatMessage({
  id,
  is_from_customer,
  leading_text,
  loading,
  products,
  animate,
  sender_type,
  handleFaqClick,
}) {
  // Regular expression pattern to match markdown links
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;

  // Replace markdown links with plain text and include the link URLs
  const message_text = leading_text?.replace(
    linkRegex,
    (match, text, url) => `${text} - ${url}`
  );

  return (
    <div
      key={id}
      class={`flex flex-col mb-[25px] ${
        is_from_customer || sender_type === "user"
          ? "flex-col-reverse items-end"
          : ""
      }`}>
      {is_from_customer || sender_type === "user" ? (
        <CustomerMessage message={leading_text} />
      ) : (
        <>
          <AgentMessage
            message={message_text}
            loading={loading}
            products={products}
            animate={animate && !products}
            senderType={sender_type}
          />
          {products ? (
            <ProductList
              recommendations={products}
              handleFaqClick={handleFaqClick}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default ChatMessage;
