import { render } from 'preact';
import './style';
import Main from "./components/Main";

export default function Hello() {
  const root = document.getElementById("austra-chat");
  if (!root) {
    const root = document.createElement("div");
    root.id = "austra-chat";
    document.body.appendChild(root);
  }
  render(<Main />, document.getElementById("austra-chat"));
}
