import SnowFlakeIcon from "./SnowFlakeIcon";
import ChevronDown from "./ChevronDown";
import { useEffect, useState } from "preact/hooks";

function ChatButton({ toggleChat, showChat }) {
  const [showNudge, setShowNudge] = useState(false);

  const toggleNudge = () => {
    setTimeout(() => {
      setShowNudge(true);
      window.removeEventListener("scroll", toggleNudge);
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleNudge);
  }, []);

  useEffect(() => {
    if (showNudge) {
      setShowNudge(false);
    }
  }, [showChat]);

  return (
    <div class="flex items-center flex-row-reverse relative">
      <button
        class={`flex items-center chatButton rounded-full shadow-lg ${
          showNudge && !showChat ? "austraNudgeMessageopen" : "austraNudgeMessageclose"
        }`}
        onClick={toggleChat}>
        {showNudge ? (
          <div class="flex-grow flex-auto">
            <p class="font-bold text-[16px] pl-[8px] pr-[8px]" style={{ color: "rgba(16, 1, 38, 0.65)" }}>
              Got questions about our products?
            </p>
          </div>
        ) : null}
        <div class="block w-[50px]">
          <div></div>
        </div>
      </button>
      <div class="absolute right-[8px]">
        <button onClick={toggleChat} class="relative" type="button">
          <ChevronDown isOpen={showChat} />
          <SnowFlakeIcon isOpen={showChat} />
        </button>
      </div>
    </div>
  );
}

export default ChatButton;
