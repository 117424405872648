import { useState, useEffect } from "preact/hooks";
import { supabase } from "../utils/supabaseclient";
import { uuidv4 } from "../utils/uuid";
import Loader from "./Loader";
import { apiBaseUri } from "../utils/apiUris";
import CollectEmailHeader from "./CollectEmailHeader";

function CollectEmailForm({ showChat, chatId, isOpen, toggleCollectEmail, userId }) {
  const [formValues, setFormvalues] = useState({
    name: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const apiUrl = `${apiBaseUri()}/api/shopper`;
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: chatId,
        name: formValues.name,
        email: formValues.email,
        user_id: userId,
      }),
    });
  }

  useEffect(() => {
    if (!isOpen) {
      setFormvalues({
        name: "",
        email: "",
      });
      setLoading(false);
    }
  }, [isOpen]);

  function handleChange(e) {
    setFormvalues({ ...formValues, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if (showChat && isOpen) {
      document.getElementById("austra-name-input").focus();
    }
  }, [showChat, isOpen]);

  return (
    <div
      class={`bg-austraPurple-100 ${
        isOpen ? "email-form-slide-up" : "slide-down"
      }`}>
      <CollectEmailHeader handleCancelButton={toggleCollectEmail} />
      <form
        id="chat-form"
        class="flex w-full rounded-b-md p-[20px] border-grey-200 gap-2 pt-[8px]"
        onSubmit={handleSubmit}>
        <div class="flex-grow ">
          <div>
            <input
              type="text"
              name="name"
              id="austra-name-input"
              value={formValues.name}
              className="w-full focus:outline-none focus:shadow-none bg-austraPurple-100 p-[6px] rounded-t-md border-1"
              placeholder="John Smith"
              style={{
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
              }}
              required
              pattern="[A-Za-z ]{1,32}"
              title="Name should only contain letters."
              onInput={handleChange}
              disabled={loading}
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              value={formValues.email}
              id="austra-email-input"
              className="w-full focus:outline-none focus:shadow-none bg-austraPurple-100 p-[6px] rounded-b-md"
              placeholder="john@example.com"
              style={{
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
              }}
              required
              onInput={handleChange}
              disabled={loading}
            />
          </div>
        </div>
        <div class="flex">
          <button type="submit" class="bg-austraPurple-100" disabled={loading}>
            {loading ? (
              <Loader />
            ) : (
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                class="Polaris-Icon__Svg"
                focusable="false"
                aria-hidden="true">
                <path d="M3.415.189a1 1 0 0 1 1.1-.046l15 9a1 1 0 0 1 0 1.714l-15 9a1 1 0 0 1-1.491-1.074l1.73-7.783h5.246a1 1 0 1 0 0-2h-5.247l-1.73-7.783a1 1 0 0 1 .393-1.028z"></path>
              </svg>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CollectEmailForm;
