import { useState, useEffect } from "preact/hooks";

function ChatInput({ sendMessage, showChat, loading, isOpen }) {
  const [message, setMessage] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (!message.trim()) return;
    if (loading) return;
    sendMessage(message);
    setMessage("");
  }

  function handleChange(e) {
    setMessage(e.target.value);
  }

  useEffect(() => {
    if (showChat && !loading && isOpen) {
      document.getElementById("austra-chat-input").focus();
    }
  }, [showChat, loading, isOpen]);

  return (
    <div
      class={`bg-austraPurple-100 ${
        isOpen ? "message-form-slide-up" : "slide-down"
      }`}>
      {/* <Recommendations /> */}
      <form
        id="chat-form"
        class="flex w-full rounded-b-md p-[20px] border-t border-grey-200 gap-2"
        onSubmit={handleSubmit}>
        <div class="flex-grow">
          <input
            id="austra-chat-input"
            value={message}
            type="text"
            placeholder="Type a message"
            class="w-full focus:outline-none focus:shadow-none bg-austraPurple-100"
            onInput={handleChange}
            autoComplete="off"
            disabled={loading}
            maxlength="300"
          />
        </div>
        <div class="flex">
          <button type="submit" class="bg-austraPurple-100" disabled={loading}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              class="Polaris-Icon__Svg"
              focusable="false"
              aria-hidden="true">
              <path d="M3.415.189a1 1 0 0 1 1.1-.046l15 9a1 1 0 0 1 0 1.714l-15 9a1 1 0 0 1-1.491-1.074l1.73-7.783h5.246a1 1 0 1 0 0-2h-5.247l-1.73-7.783a1 1 0 0 1 .393-1.028z"></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChatInput;
