function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-user"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="white"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
    </svg>
  );
}

function UserIcon({ size = "lg", isOpen = true }) {
  if (size === "sm") {
    return (
      <div class="bg-austraPurple-500 flex rounded-full w-[25px] h-[25px] items-center justify-center p-[4px]">
        <Icon />
      </div>
    );
  }
  return (
    <div
      class={`bg-austraPurple-900 flex rounded-full w-[50px] h-[50px] items-center justify-center py-[9px] px-[9.4px] ${
        !isOpen ? "buttoniconshow" : "buttoniconhide"
      }`}>
      <UserIcon />
    </div>
  );
}

export default UserIcon;
