import { useEffect, useState } from "preact/hooks";

import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import ChatMessages from "./ChatMessages";
import { uuidv4 } from "../utils/uuid";
import { setCookie, getCookie, clearCookies } from "../utils/cookieUtils";
import ErrorMessage from "./ErrorMessage";
import { supabase } from "../utils/supabaseclient";
import CollectEmailForm from "./CollectEmailForm";
import { apiBaseUri } from "../utils/apiUris";
import { Recommendation } from "./Recommendations";
const channel = "test";

const apiUrl = `${apiBaseUri()}/api/agent/ask`;

function ChatInterface({ showChat, shopId, storeWidgetPrefix, toggleChat }) {
  const device_id = localStorage.getItem("austra_device_id") || uuidv4();
  const [chatId, setChatId] = useState(getCookie("chat_id"));
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [messages, setMessages] = useState(JSON.parse(localStorage.getItem(chatId)) || []);
  const [error, setError] = useState(null);
  const [collectEmail, setCollectEmail] = useState(
    messages.length ? messages[messages.length - 1].collect_email : false
  );
  const [subscribed, setSubscribed] = useState(false);
  const [eventQueue, setEventQueue] = useState([]);
  const [showTalkWithAIPill, setShowTalkWithAIPill] = useState(false);

  const createUser = async () => {
    const { data, error } = await supabase.auth.signUp({
      email: `${uuidv4()}@austracustomer.com`,
      password: uuidv4(),
    });
    localStorage.setItem("user", JSON.stringify(data.user));
    return data.user;
  };

  const createChat = async (user_id) => {
    const { data, error } = await supabase
      .from("Chat")
      .insert([
        {
          id: uuidv4(),
          user_id: user_id,
          shop_id: shopId,
          device_id: device_id,
        },
      ])
      .select();
    setCookie("chat_id", data[0].id, 30);

    window.gtag("event", "chat-started", {
      event_category: storeWidgetPrefix,
      event_label: `${storeWidgetPrefix}-chat-started`,
      value: data[0].id,
    });

    return data[0].id;
  };

  // this is for showing welcome message
  useEffect(() => {
    if (!messages.length && showChat) {
      setMessages([
        {
          leading_text:
            "Hi there! I'm Euclove's AI Shopping Assistant, ready to help you.\n\nWhether you have questions about our products or need help deciding which one to purchase, feel free to ask. 😃",
          animate: false,
        },
      ]);
    }
  }, [showChat]);

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      const chat_id = getCookie("chat_id");
      if (window.location.href.includes(8080)) {
        clearCookies();
        localStorage.removeItem(chat_id);
      }
    });
  }, []);

  const handleSendMessage = async (message) => {
    // third make sure chat record is created
    let chat_id = getCookie("chat_id");
    const message_id = uuidv4();
    const newMessage = {
      id: message_id,
      leading_text: message,
      chat_id: chat_id,
      sender_type: "user",
    };
    setMessages((messages) => {
      messages.map((message) => {
        message.animate = false;
        return message;
      });
      // there is no need to show loader if user is talking with agent
      if (showTalkWithAIPill) {
        return [...messages, newMessage];
      } else {
        return [...messages, newMessage, { loading: true }];
      }
    });

    // first make user exits
    const {
      data: { session },
    } = await supabase.auth.getSession();
    console.log({ session });
    let userDetails = session ? session.user : null;
    if (!userDetails) {
      userDetails = await createUser();
      setUser(userDetails);
      chat_id = null;
    }

    if (!chat_id) {
      chat_id = await createChat(userDetails.id);
      setChatId(chat_id);
    }

    // second make sure user is subscribed to channel
    if (!subscribed) {
      createSubscription();
      broadcastPresence(chat_id);
    }

    // finally send message
    if (userDetails && chat_id) {
      if (showTalkWithAIPill) {
        saveMessageInSupabase(message, chat_id, message_id);
      } else {
        sendMessage(message, chat_id, message_id);
      }
    }
  };

  const sendMessage = async (message, chat_id, message_id) => {
    // make post request to api
    setCollectEmail(false); // hide email form
    setLoading(true);
    setError(null);
    try {
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: chat_id,
          message: message,
          message_id: message_id,
        }),
      })
        .then((response) => {
          response.json().then((data) => {});
        })
        .catch((error) => {
          console.log(error);
          setError("Something went wrong. Please try again.");
          setMessages((messages) => {
            messages.pop();
            return messages;
          });
          setLoading(false);
        });
    } catch (error) {
      setError("Something went wrong. Please try again.");
      setMessages((messages) => {
        messages.pop();
        return messages;
      });
    }
  };

  // we will directly save message in supabase if user is talking with shop owner
  const saveMessageInSupabase = async (message, chat_id, message_id, sender_type) => {
    await supabase
      .from("ChatMessage")
      .insert({
        id: message_id,
        chat_id: chat_id,
        leading_text: message,
        sender_type: sender_type || "user",
      })
      .select();
    setLoading(false);
  };

  useEffect(() => {
    if (eventQueue.length) {
      handleNewMessage();
    }
  }, [eventQueue]);

  const handleNewMessage = () => {
    setCollectEmail(false);
    // we will chat with AI pill if last message is from owner of store
    setShowTalkWithAIPill(eventQueue[eventQueue.length - 1].sender_type === "owner");
    setMessages((messages) => {
      let newMessageExits = false;
      eventQueue.forEach((event) => {
        const doesMessageExit = messages.filter((message) => {
          return message.id === event.id;
        });
        if (!doesMessageExit.length) {
          newMessageExits = true;
          messages.push(event);
        }
      });
      if (!newMessageExits) {
        return messages;
      }
      setLoading(false);
      // for removing loader
      messages.map((message) => {
        message.animate = false;
        return message;
      });
      if (messages[messages.length - 1].collect_email) {
        setCollectEmail(true);
      }
      // for animating text of last message
      messages[messages.length - 1].animate = true;
      let chat_id = getCookie("chat_id");
      messages = messages.filter((message) => !message.loading);
      const updatedMessages = [...messages];
      localStorage.setItem(chat_id, JSON.stringify(updatedMessages));
      return updatedMessages;
    });
  };

  const createSubscription = async () => {
    setSubscribed(true);
    supabase
      .channel(channel)
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "ChatMessage",
        },
        (payload) => {
          if (payload.new.collect_email) {
            setCollectEmail(true);
          }
          if (payload.new.sender_type !== "user") {
            setEventQueue((prevQueue) => [...prevQueue, payload.new]);
          }
        }
      )
      .subscribe();
  };

  const toggleCollectEmail = () => {
    setCollectEmail(!collectEmail);
  };

  const toggleShowTalkWithAIPill = () => {
    setShowTalkWithAIPill(false);
  };

  const broadcastPresence = async (chatId) => {
    const liveChannel = supabase.channel(shopId, {
      config: {
        presence: {
          key: chatId,
        },
      },
    });
    liveChannel
      .on("presence", { event: "join" }, ({ newPresences, key }) => {
        console.log("Newly joined presences: ", newPresences, key);
      })
      .on("presence", { event: "leave" }, ({ leftPresences, key }) => {
        console.log("Newly left presences: ", leftPresences, key);
      })
      .subscribe(async (status) => {
        if (status === "SUBSCRIBED") {
          await liveChannel.track({ online_at: new Date().toISOString() });
        }
      });
  };

  const handleFaqClick = ({ question, answer }) => {
    let chat_id = getCookie("chat_id");
    const message_id = uuidv4();
    const newMessage = {
      id: message_id,
      leading_text: question,
      chat_id: chat_id,
      sender_type: "user",
    };
    setMessages((messages) => {
      messages.map((message) => {
        message.animate = false;
        return message;
      });
      return [...messages, newMessage, { loading: true }];
    });
    saveMessageInSupabase(question, chat_id, message_id, "user");
    saveMessageInSupabase(answer, chat_id, uuidv4(), "assistant");
  };

  return (
    <div class={`${showChat ? "open" : "close"} flex flex-col`} style={{ backgroundColor: "#F9F9F9" }}>
      <ChatHeader title={"Euclove"} toggleChat={toggleChat} />
      <ChatMessages messages={[...messages]} handleFaqClick={handleFaqClick} />
      <ErrorMessage error={error} />
      <CollectEmailForm
        showChat={showChat}
        chatId={chatId}
        isOpen={collectEmail}
        toggleCollectEmail={toggleCollectEmail}
        userId={user?.id}
      />
      {showTalkWithAIPill ? (
        <div class="flex rounded-[12px] rounded-bl-none rounded-br-none p-2 px-[20px] items-center justify-between zoomElement">
          <Recommendation text="Chat with AI" handleClick={toggleShowTalkWithAIPill} />
        </div>
      ) : null}

      <ChatInput
        sendMessage={handleSendMessage}
        showChat={showChat}
        loading={loading}
        collectEmail={collectEmail}
        isOpen={!collectEmail}
      />
    </div>
  );
}

export default ChatInterface;
