import { IconX } from "@tabler/icons-react";

function CollectEmailHeader({ handleCancelButton }) {
  return (
    <div
      class={`flex rounded-[12px] rounded-bl-none rounded-br-none bg-austraPurple-100 p-2 px-[20px] items-center justify-between`}>
      <div class="flex items-center">
        <h3 class="text-[14px] font-medium">Your Name and Email</h3>
      </div>
      <button
        class="inline-flex rounded-full p-[6px] mr-[-6px] hover:text-red-700 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
        onClick={handleCancelButton}>
        <IconX size={14} />
      </button>
    </div>
  );
}

export default CollectEmailHeader;
