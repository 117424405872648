function ErrorMessage({ error }) {
  if (!error) return null;
  return (
    <div
      className={`flex rounded-[12px] rounded-bl-none rounded-br-none bg-red-50 p-2 px-[20px]`}>
      <h3 className="text-sm font-medium text-red-800">{error}</h3>
    </div>
  );
}

export default ErrorMessage;
