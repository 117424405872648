function Icon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="chevron-down">
        <path
          id="Vector"
          d="M6.04492 9.83984L12.0449 15.8398L18.0449 9.83984"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

function ChevronDown({ isOpen }) {
  return (
    <div
      class={`bg-slate-950 flex rounded-full w-[50px] h-[50px] items-center justify-center py-[9px] px-[9px] chevron-down ${
        isOpen ? "buttoniconshow" : "buttoniconhide"
      }`}>
      <Icon />
    </div>
  );
}

export default ChevronDown;
