import { useEffect, useState } from "preact/hooks";
import ChatInterface from "./ChatInterface";
import ChatButton from "./ChatButton";
import isItMobile from "../utils/mobileDetector";
import { uuidv4 } from "../utils/uuid";
const cssUrl = `${process.env.PREACT_APP_VERCEL_URL}/bundle.css`;

let fetchStyle = function (url) {
  return new Promise((resolve, reject) => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.onload = () => resolve();
    link.onerror = () => reject();
    link.href = url;

    let headScript = document.querySelector("script");
    headScript.parentNode.insertBefore(link, headScript);
  });
};

const initGA = () => {
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.PREACT_APP_GA_ID}`;
  script.async = true;
  document.body.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    dataLayer.push(arguments);
  };
  window.gtag("js", new Date());

  window.gtag("config", process.env.PREACT_APP_GA_ID);
};

const plantDeviceID = () => {
  const deviceID = uuidv4();
  if (!sessionStorage.getItem("austra-device-id")) {
    sessionStorage.setItem("austra-device-id", deviceID);
  }
};

function Main() {
  const [showChat, setShowChat] = useState(false);
  const [cssLoaded, setCssLoaded] = useState(window.location.href.includes(8080));
  const [enableAustra, setEnableAustra] = useState(!isItMobile(navigator.userAgent));
  const [storeWidgetPrefix, setStoreWidgetPrefix] = useState("AUSTRA");

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    if (!cssLoaded) {
      fetchStyle(cssUrl).then(
        () => {
          console.log("Font loaded");
          setCssLoaded(true);
        },
        () => {
          console.log("Font not loaded");
        }
      );
    }

    setStoreWidgetPrefix("AUSTRA-EUCLOVE");

    // initialize GA
    initGA();

    toggleShopifyChatView();
  }, []);

  useEffect(() => {
    // Create a new MutationObserver instance
    const observer = new MutationObserver((mutationsList, observer) => {
      // Loop through the mutations
      for (const mutation of mutationsList) {
        // Check if new nodes were added
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          // Handle the newly added nodes here
          toggleShopifyChatView();
        }
      }
    });
    const target = document.querySelector("body");
    observer.observe(target, { childList: true, subtree: true });
  }, []);

  const toggleShopifyChatView = () => {
    const vw = window.innerWidth;
    const shopifyChat = document.getElementById("shopify-chat");
    const dummyIframe = document.getElementById("dummy-chat-button-iframe");

    if (!isItMobile()) {
      if (!sessionStorage.getItem("austra-device-id")) {
        plantDeviceID();
        window.gtag("event", "desktop-visit", {
          event_category: storeWidgetPrefix,
          event_label: `${storeWidgetPrefix}-desktop-visit`,
        });
      }
      if (dummyIframe) {
        dummyIframe.parentElement.removeChild(dummyIframe);
      }
      if (shopifyChat) {
        shopifyChat.style.display = "none";
      }
    } else {
      if (!sessionStorage.getItem("austra-device-id")) {
        plantDeviceID();
        window.gtag("event", "mobile-visit", {
          event_category: storeWidgetPrefix,
          event_label: `${storeWidgetPrefix}-mobile-visit`,
        });
      }
      if (shopifyChat) {
        shopifyChat.style.display = "block";
      }
    }
  };

  function toggleChat() {
    if (!showChat) {
      window.gtag("event", "chat-opened", {
        event_category: storeWidgetPrefix,
        event_label: `${storeWidgetPrefix}-chat-opened`,
        open_page_location: window.location.href,
      });
    } else {
      window.gtag("event", "chat-closed", {
        event_category: storeWidgetPrefix,
        event_label: `${storeWidgetPrefix}-chat-closed`,
      });
    }
    setShowChat(!showChat);
  }

  if (!cssLoaded) {
    return null;
  }

  if (!enableAustra) {
    return null;
  }

  return (
    <div style={{ fontSize: "16px" }} class="fixed right-5 bottom-9 z-[1000]">
      <div class="relative">
        <ChatInterface
          showChat={showChat}
          shopId="6df9e6a9-74ab-43af-bfdd-83b15ed17d28"
          storeWidgetPrefix={storeWidgetPrefix}
          toggleChat={toggleChat}
        />
        <ChatButton toggleChat={toggleChat} showChat={showChat} />
      </div>
    </div>
  );
}

export default Main;
