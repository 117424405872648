import "../loader.css";

function Loader() {
  return (
    <div class="loading-bars">
      <div class="bar block"></div>
      <div class="bar block"></div>
      <div class="bar block"></div>
      <div class="bar block"></div>
      <div class="bar block"></div>
    </div>
  );
}

export default Loader;
