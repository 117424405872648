const recommendationsOptions = [
  "help me find the best serum for my skin",
  "what are serums?",
  "what are other things?",
  "what can help with my acne spots?",
];

export function Recommendation({ text, handleClick }) {
  return (
    <button
      type="button"
      onClick={handleClick}
      class="bg-austraPurple-600 py-[6px] px-[10px] rounded-[6px] flex align-middle hover:bg-austraPurple-800 hover:text-white w-fit text-left">
      {text}
    </button>
  );
}

function Recommendations() {
  return (
    <div class="flex gap-2 p-[12px] flex-wrap">
      {recommendationsOptions.map((option) => (
        <Recommendation text={option} />
      ))}
    </div>
  );
}

export default Recommendations;
