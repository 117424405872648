function Icon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="chevron-down">
        <path
          d="M3.52734 16H33.0296"
          stroke="white"
          stroke-width="2.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.2793 2.66602V29.3327"
          stroke="white"
          stroke-width="2.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.0801 21.3327L24.1797 15.9993L30.0801 10.666"
          stroke="white"
          stroke-width="2.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.47852 10.666L12.379 15.9993L6.47852 21.3327"
          stroke="white"
          stroke-width="2.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.1798 5.33398L18.2794 10.6673L12.3789 5.33398"
          stroke="white"
          stroke-width="2.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.3789 26.6673L18.2794 21.334L24.1798 26.6673"
          stroke="white"
          stroke-width="2.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

function SnowFlakeIcon({ size = "lg", isOpen = true }) {
  if (size === "sm") {
    return (
      <div class="bg-austraPurple-500 flex rounded-full w-[25px] h-[25px] items-center justify-center p-[4px]">
        <Icon />
      </div>
    );
  }
  return (
    <div
      class={`bg-austraPurple-900 flex rounded-full w-[50px] h-[50px] items-center justify-center py-[9px] px-[9.4px] ${
        !isOpen ? "buttoniconshow" : "buttoniconhide"
      }`}>
      <Icon />
    </div>
  );
}

export default SnowFlakeIcon;
