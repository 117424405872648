import StarIcon from "./StarIcon";
import AnimateText from "./AnimateText";
import { Recommendation } from "./Recommendations";
import { useEffect, useState } from "preact/hooks";

function ProductTopSection({
  title,
  featuredImage,
  ratingCount,
  ratingValue,
  productUrl,
  price,
  discountPrice,
}) {
  return (
    <div class="flex gap-4">
      <div class="aspect-square overflow-hidden rounded-[12px] bg-gray-200 lg:aspect-none group-hover:opacity-75 h-[120px] w-[120px]">
        <img
          src={featuredImage || "https://via.placeholder.com/150"}
          alt="logo"
          class="w-full object-cover object-center h-full"
        />
      </div>
      <div class="flex flex-col justify-between">
        <div>
          <h3 class="font-black text-[14px]">{title}</h3>
          <div class="mt-[8px] flex flex-wrap gap-[12px] gap-y-[2px] mb-[8px]">
            <div class="flex gap-[4px]">
              {price ? (
                <span class="text-[12px] font-bold flex items-center">
                  $ {price}
                </span>
              ) : null}
              {discountPrice ? (
                <span class="text-[12px] font-light flex items-center line-through">
                  $ {price}
                </span>
              ) : null}
            </div>
            <div>
              {ratingValue ? (
                <span class="text-[12px] flex items-center">
                  <StarIcon /> {ratingValue} ({ratingCount || 0})
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <a
            href={productUrl}
            class="bg-austraPurple-900 text-white py-[6px] px-[10px] rounded-[4px] flex align-middle w-fit"
            target="_blank">
            View product
          </a>
        </div>
      </div>
    </div>
  );
}

function ProductDescription({ relevancy, faqs, handleFaqClick }) {
  const [showFaqs, setShowFaqs] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowFaqs(true);
    }, 30 * relevancy.length);
  }, []);

  return (
    <div class="flex flex-col">
      <AnimateText text={relevancy} />
      {/* {showFaqs && faqs?.length ? (
        <div class="flex flex-row gap-4 border-t border-grey-200 pt-[12px] mt-[12px] flex-wrap">
          {faqs.map(({ question, answer }, key) => {
            if (question && answer) {
              return (
                <div class="zoomElement">
                  <Recommendation
                    text={question}
                    handleClick={() => handleFaqClick({ question, answer })}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      ) : null} */}
    </div>
  );
}

function Product({
  id,
  title,
  price,
  featuredImage,
  ratingCount,
  ratingValue,
  relevancy,
  productUrl,
  faqs,
  handleFaqClick,
}) {
  return (
    <div
      key={id}
      class="group relative bg-white p-[14px] w-[95%] rounded-[12px] flex flex-shrink-0 flex-col h-fit"
      style={{
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        transform: "height 0.3s ease",
      }}>
      <ProductTopSection
        id={id}
        title={title}
        price={price}
        featuredImage={featuredImage}
        ratingCount={ratingCount}
        ratingValue={ratingValue}
        relevancy={relevancy}
        productUrl={productUrl}
      />
      <div
        class="h-[1px] w-full my-[22px] border-t-2 border-dashed border-black-600"
        style={{ display: "block" }}
      />
      <ProductDescription
        relevancy={relevancy}
        faqs={faqs}
        handleFaqClick={handleFaqClick}
      />
    </div>
  );
}

export default Product;
