import { useState, useEffect } from "preact/hooks";
import CursorSVG from "./CursorSVG";
import Linkify from "linkify-react";

function AnimateText({ text }) {
  const [displayResponse, setDisplayResponse] = useState();
  const [completedTyping, setCompletedTyping] = useState(false);

  useEffect(() => {
    if (text) {
      let i = 0;
      const stringResponse = text;

      const intervalId = setInterval(() => {
        setDisplayResponse(stringResponse.slice(0, i));

        i++;

        if (i > stringResponse.length) {
          clearInterval(intervalId);
          setCompletedTyping(true);
        }
      }, 30);
    }
  }, []);

  return (
    <p class="whitespace-pre-wrap chatMessage break-anywhere">
      <Linkify
        options={{
          target: {
            url: "_blank",
          },
          className: "text-austraPurple-900",
        }}>
        {displayResponse}
        {!completedTyping ? <CursorSVG /> : null}
      </Linkify>
    </p>
  );
}

export default AnimateText;
