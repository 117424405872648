function StarIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0L7.854 3.94953L12 4.58675L9 7.65931L9.708 12L6 9.94953L2.292 12L3 7.65931L0 4.58675L4.146 3.94953L6 0Z"
        fill="#FFCE1F"
      />
    </svg>
  );
}

export default StarIcon;
